import { useCallback, useContext } from "react";
import { navigate } from "gatsby";
import { DataContext } from "../contexts/DataContext";

const useNavigate = (to, callback) => {
  const { i18nLink, i18nOasiLink } = useContext(DataContext);
  const oasiPaths =["/trees", "/alveari"];
  const openFreshChat = useCallback(() => {
    if (typeof window !== "undefined") {
      window.FreshworksWidget("open");
    }
  }, []);

  const scrollToRef = useCallback(()=> {
    const element = document.getElementById(to.replace(/#/i, ""));
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ // usare useREF al posto di scrollTo
      top: y,
      behavior: "smooth", 
    });
  }, [to]);

  if (to) {
    const isScrollTo = to.startsWith("#");
    const isChat = to.startsWith("chat");
    const isExternal = to.startsWith("http");
    if (isScrollTo){
      return () => {
        scrollToRef();
        if (callback) {
          callback();
        }
      };
    }
    if (isChat){
      return () => { 
        openFreshChat();
        if (callback) {
          callback();
        }
      };
    }
    if (isExternal) {
      return () => {
        window.location.href = to;
        if (callback) {
          callback();
        }
      };
    }
    if (oasiPaths.includes(to) || to.startsWith("/owner")) {
      return () => {
        window.location.href = i18nOasiLink(to);
        if (callback) {
          callback();
        }
      };
    }
    return () => {
      navigate(i18nLink(to));
      if (callback) {
        callback();
      }
    };
  }
  return null;
};

export default useNavigate;

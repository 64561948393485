import React, { useContext, useState } from "react";
import { DataContext } from "../contexts/DataContext";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import { StyledGridRow } from "../commons/Grid";
import Meta from "../components/Meta/Meta";
import styled from "styled-components";
import { Colors } from "../commons/Theme";
import useTimeoutCallback from "../hooks/useTimeoutCallback";

const StyledDeclaration = styled(StyledGridRow)`
  a {
    color: ${Colors.yellow};
    text-decoration: underline;
  }
`;

const CookieDeclaration = () => {
  const { languages, pageLabels, pageImages } = useContext(DataContext);

  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  const [showCookiebot, setShowCookiebot] = useState(false);
  useTimeoutCallback(
    () => setShowCookiebot(true),
    {
      timeout: 1000,
    },
  );


  return (
    <>
      <Meta showCookiebotImmediately={true} />
      
      <Hero3BC
        title = {pageLabels.hero.title}
        image = {pageLabels.hero.image}
        allImages = {pageImages}
        noNewsletter={true}
      />

      <div style={{ height: "5.5rem" }}></div>

      <StyledDeclaration>
        {showCookiebot && (
          <script 
            id="CookieDeclaration" 
            data-culture={currentLanguageCode.toUpperCase()}
            src="https://consent.cookiebot.com/fe6edc14-a03f-4d0e-a577-5f65f9791eda/cd.js"
            type="text/javascript"
            async/>
        )}
      </StyledDeclaration>
    </>
  );
};

export default CookieDeclaration;
